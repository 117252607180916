import {customFetch} from "~/composables/customFetch";
import {H3Error} from "h3";
import {useInitializing} from "~/composables/useInitializing";

import { useVisitedUrlStore } from '~/stores/visitedUrl';

const errFunc = console.error
window.errFunc = errFunc;
window.commonErrorHandler = (param: any) => {
    let error = param;
    if (param.reason) {
        error = param.reason;
    }
    if (error && error.reason) {
        error = error.reason;
    }
    let msg = error.message;
    if (error.data && error.data.message) {
        msg = error.data.message + "\n" + msg;
    }
    const route = useRoute();

    const { isInitialized } = useInitializing();
    if (isInitialized() && (!error || !error.endpoint || !error.endpoint.endsWith("/error/public/report"))) {
        let response = error && error.response ? error.response : undefined;
        if (response) {
            if (response._data) {
                response = response._data;
            }
            if (typeof response !== "string") {
                response = JSON.stringify(response);
            }
        }
        let options = error && error.options ? error.options : undefined;
        if (options) {
            if (typeof options !== "string") {
                options = JSON.stringify(options);
            }
        }
        const visitedUrlStore = useVisitedUrlStore();
        let visitedUrls = JSON.stringify(visitedUrlStore.visitedUrls);
        customFetch("/error/public/report", {
            method: "POST",
            body: {
                userAgent: navigator.userAgent,
                stackTrace: error.stack,
                msg: msg,
                path: route.path,
                options,
                request: error && error.request ? error.request : undefined,
                response,
                visitedUrls
            },
        });
    }
}

window.getStackTrace = function() {
    const obj = {}
    Error.captureStackTrace(obj, window.getStackTrace)
    return obj.stack
}
console.error = (...args) => {
    window.errFunc(...args);

    let message = "No message";
    let foundH3Error = false;
    let reason;
    if (args) {
        for (let i = 0; i < args.length; i++) {
            if (args[i] instanceof H3Error) {
                window.commonErrorHandler(args[i]);
                foundH3Error = true;
            }
        }
        if (!foundH3Error) {
            message = "";
            for (let i = 0; i < args.length; i++) {
                if (typeof args[i] == "string") {
                    message += args[i] + " ";
                } else if (args[i] instanceof Error) {
                    reason = args[i];
                    message += args[i] + " ";

                } else {
                    message += JSON.stringify(args[i]) + " ";
                }
            }
            if (!message) {
                message = "No available message";
            }
        }
    }
    if (!foundH3Error) {
        window.commonErrorHandler({
            message: message,
            stack: window.getStackTrace(),
            reason
        });
    }
}

export default defineNuxtPlugin(() => {
    window.onerror = function (message, source, lineno, colno, error) {
        console.error("Error caught:", message, error);
        // 추가적인 로직을 원하면 여기에 추가
        return true; // 예외가 이미 처리되었음을 브라우저에 알림
    };

    window.onunhandledrejection = function (event) {
        console.error("Unhandled Promise Rejection:", event.reason);
        return true; // 예외가 이미 처리되었음을 브라우저에 알림
    };
})