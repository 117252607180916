import { FetchError } from "ofetch";
import { useLoading } from "~/composables/useLoading";
const { setTokens, clearTokens } = useAuth();

function createHttpRequestText({ request, options }: any) {
  const { method = "GET", headers = {}, body } = options;

  // 1. HTTP 메서드와 URL 설정
  let httpRequestText = `${method} ${request} HTTP/1.1\n`;

  // 2. 헤더 설정
  for (const [header, value] of Object.entries(headers)) {
    httpRequestText += `${header}: ${value}\n`;
  }

  // 3. 바디가 있으면 추가 (POST, PUT 등)
  if (body) {
    let processedBody = typeof body === "string" ? body : JSON.stringify(body);
    if (processedBody.length > 100000) {
      processedBody = processedBody.substring(0, 1000000) + "...";
    }
    httpRequestText += `\n${processedBody}`;
  }

  return httpRequestText;
}
function createHttpResponseText(response: any, data: any) {
  if (!response) {
    return "";
  }
  // 1. 응답 상태 코드와 상태 메시지 설정
  let httpResponseText = `HTTP/1.1 ${response.status} ${response.statusText}\n`;

  // 2. 응답 헤더 설정
  for (const [header, value] of response.headers.entries()) {
    httpResponseText += `${header}: ${value}\n`;
  }

  // 3. 응답 본문 설정
  httpResponseText += "\n"; // 헤더와 바디 사이의 빈 줄

  // 응답 본문 추가
  let processedBody;
  if (typeof data === "string") {
    processedBody = data;
  } else if (typeof data === "object") {
    processedBody = JSON.stringify(data, null, 2);
  }
  if (processedBody) {
    if (processedBody.length > 100000) {
      processedBody = processedBody.substring(0, 1000000) + "...";
    }
    httpResponseText += processedBody;
  }

  return httpResponseText;
}

const refreshAccessToken = async () => {
  try {
    const { refreshToken } = useAuth();
    const { data }: any = await customFetch("/user/public/refreshToken", {
      method: "POST",
      body: { refreshToken: refreshToken.value },
      headers: {
        Authorization: `Bearer ${refreshToken.value}`,
      },
    });
    setTokens(data.token, data.refreshToken);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    clearTokens(); // 토큰 갱신 실패 시 로그아웃 처리 등
    navigateTo("/login?from=v1");
    return false;
  }
  return true;
};

export async function customFetchProcess(
  endpoint: string,
  options: any = {},
  useUseFetch: boolean,
) {
  if (!Object.hasOwn(options, "retry")) {
    options.retry = false;
  }
  const config = useRuntimeConfig();
  const { startLoading, stopLoading } = useLoading();

  if (!options.hideLoading) {
    startLoading();
  }
  options.headers = options.headers || {};
  if (localStorage.locale) {
    const locale = JSON.parse(localStorage.locale);
    options.headers["X-Locale-ID"] = locale.id;
    options.headers["X-Locale-Alias"] = locale.alias;
  }
  options.headers["X-Site-ID"] = config.public.siteId;
  options.headers["X-Site-ALIAS"] = config.public.siteAlias;
  options.headers["X-Front-Domain"] = config.public.domain;
  const url = `${config.public.apiBaseUrl}${endpoint}`;
  async function handleError(error: any, endpoint: string) {
    error.endpoint = endpoint;
    if (
      error.data &&
      error.data.resultCode === "jwt_token_expired" &&
      !endpoint.endsWith("/user/public/refreshToken")
    ) {
      // 토큰 만료, 갱신 시도
      try {
        if (!(await refreshAccessToken())) {
          if (!options.hideLoading) {
            stopLoading();
          }
          if (!`${endpoint}`.includes("/public/")) {
            return;
          } else {
            clearTokens();
            if (options && options.headers && options.headers.Authorization) {
              delete options.headers.Authorization;
            }
          }
        }
        const { token } = useAuth();
        if (
          token &&
          token.value &&
          !endpoint.endsWith("/user/public/refreshToken")
        ) {
          options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token.value}`,
          };
        }
        let fetchRequest;
        let fetchOptions;
        options.onRequest = ({ request, options }: any) => {
          fetchRequest = createHttpRequestText({ request, options });
          fetchOptions = options;
        };
        let res;
        options.onResponse = ({ response }: any) => {
          res = response;
        };
        if (useUseFetch) {
          const { data, error } = await useFetch(url, options);
          const fetchResponse = createHttpResponseText(
            res,
            data.value
              ? data.value
              : error && error.value
                ? error.value.data
                : undefined,
          );
          if (error && error.value) {
            error.value.request = fetchRequest;
            error.value.response = fetchResponse;
            if (fetchOptions) {
              error.value.options = JSON.stringify(fetchOptions);
            }
          }
          if (!options.hideLoading) {
            stopLoading();
          }
          return { data, error };
        }
        if (!options.hideLoading) {
          stopLoading();
        }
        try {
          return await $fetch(url, options);
        } catch (e: any) {
          if (e instanceof FetchError) {
            e.reason = {
              endpoint: e.endpoint ? e.endpoint : endpoint,
              name: e.name,
              data: e.data,
              message: e.message,
              options: e.options,
              request: fetchRequest,
              response: createHttpResponseText(
                e.response,
                e.response ? e.response.data : undefined,
              ),
              stack: e.stack,
              status: e.status,
              statusCode: e.statusCode,
              statusMessage: e.statusMessage,
              statusText: e.statusText,
            };
          }
          e.passException = true;
          if (e.response && e.response._data && e.response._data.message) {
            makeToast(e.response._data.message, "danger");
          }
          throw e;
        }
      } catch (refreshError: any) {
        if (refreshError.passException) {
          throw refreshError;
        }
        if (!options.hideLoading) {
          stopLoading();
        }
        clearTokens(); // 토큰 갱신 실패 시 로그아웃 처리 등
        // 리프레시 실패 처리. 로그인 페이지로 전환.
        navigateTo("/login?from=v2");
        return;
      }
    }
    if (!options.hideLoading) {
      stopLoading();
    }
    if (error && error.data && error.data.message) {
      makeToast(error.data.message, "danger");
    }
    throw error;
  }
  const { token } = useAuth();
  if (token && token.value && !endpoint.endsWith("/user/public/refreshToken")) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token.value}`,
    };
  }

  let fetchRequest;
  let fetchOptions;
  options.onRequest = ({ request, options }) => {
    fetchRequest = createHttpRequestText({ request, options });
    fetchOptions = options;
  };
  let res;
  options.onResponse = ({ response }) => {
    res = response;
  };
  if (useUseFetch) {
    const { data, error } = await useFetch(url, options);
    const fetchResponse = createHttpResponseText(
      res,
      data.value
        ? data.value
        : error && error.value
          ? error.value.data
          : undefined,
    );
    if (error && error.value) {
      error.value.request = fetchRequest;
      error.value.response = fetchResponse;
      if (fetchOptions) {
        error.value.options = JSON.stringify(fetchOptions);
      }
      if (!options.hideLoading) {
        stopLoading();
      }
      const res = await handleError(error.value, endpoint);
      if (res) {
        return res;
      }
    }
    if (!options.hideLoading) {
      stopLoading();
    }
    return {
      data,
      error,
    };
  }
  if (!options.hideLoading) {
    stopLoading();
  }
  try {
    options.response = true;
    return await $fetch(url, options);
  } catch (e) {
    if (e instanceof FetchError) {
      e.reason = {
        endpoint: e.endpoint ? e.endpoint : endpoint,
        name: e.name,
        data: e.data,
        message: e.message,
        options: e.options,
        request: fetchRequest,
        response: createHttpResponseText(
          e.response,
          e.response ? e.response.data : undefined,
        ),
        stack: e.stack,
        status: e.status,
        statusCode: e.statusCode,
        statusMessage: e.statusMessage,
        statusText: e.statusText,
      };
    }
    const ret = await handleError(e, endpoint);
    if (!ret) {
      throw e;
    }
    return ret;
  }
}

export async function customFetch(endpoint: string, options: any = {}) {
  return await customFetchProcess(endpoint, options, false);
}
