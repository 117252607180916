import { useVisitedUrlStore } from '~/stores/visitedUrl';

export default defineNuxtPlugin((nuxtApp: any) => {
    const urlStore = useVisitedUrlStore();

    nuxtApp.$router.beforeEach((to: any, from: any, next: any) => {
        urlStore.addUrl(to.fullPath); // 경로를 Pinia 스토어에 추가. 오류 발생시 추적용.

        let routes;
        if (localStorage.visitedRoutes) {
            routes = JSON.parse(localStorage.visitedRoutes);
        } else {
            routes = [];
        }
        routes.push(to);
        localStorage.visitedRoutes = JSON.stringify(routes);
        next(); // 라우팅 계속 진행
    });
});