import { ref } from "vue";

const isInitializing = ref(true);
export function useInitializing() {
  const setInitialized = () => {
    isInitializing.value = false;
  };

  const isInitialized = () => !isInitializing.value;

  return {
    setInitialized,
    isInitialized,
  };
}
